import { Suspense, lazy } from 'react';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const HomePage = Loadable(lazy(() => import('./pages/PlayRoulettePage')));

const routes = [
  {
    path: '',
    element: <HomePage />
  },
];

export default routes;
