import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import apollo from '../lib/apollo';
import { LOGIN, ADD } from '../graphql/mutations/auth';
import { GET_PROFILE } from '../graphql/queries/user';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  isLoginDialogOpen: false,
  isRegisterDialogOpen: false,
  isSuccessRegisterDialogOpen: false
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    console.log('user', user);

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  OPEN_LOGIN_DIALOG(state) {
    return {
      ...state,
      isLoginDialogOpen: true,
    };
  },
  CLOSE_LOGIN_DIALOG(state) {
    return {
      ...state,
      isLoginDialogOpen: false,
    };
  },
  OPEN_REGISTER_DIALOG(state) {
    return {
      ...state,
      isRegisterDialogOpen: true,
    };
  },
  CLOSE_REGISTER_DIALOG(state) {
    return {
      ...state,
      isRegisterDialogOpen: false,
    };
  },
  OPEN_SUCCESS_REGISTER_DIALOG(state) {
    return {
      ...state,
      isSuccessRegisterDialogOpen: true,
    };
  },
  CLOSE_SUCCESS_REGISTER_DIALOG(state) {
    return {
      ...state,
      isSuccessRegisterDialogOpen: false,
    };
  },
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  openLoginDialog: () => {},
  closeLoginDialog: () => {},
  openRegisterDialog: () => {},
  closeRegisterDialog: () => {},
  openSuccessRegisterDialog: () => {},
  closeSuccessRegisterDialog: () => {}
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getProfile = async () => (await apollo.apolloClient.query({
    query: GET_PROFILE
  })).data.profile;

  useEffect(async () => {
    const initialize = async () => {
      try {
        const accessToken = Cookies.get('accessToken');

        if (accessToken) {
          const userProfile = await getProfile();

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user: userProfile
            }
          });

          return;
        }

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const logout = async () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    apollo.apolloClient.resetStore();
    dispatch({ type: 'LOGOUT' });
  };

  const login = async (username, password) => {
    const { data: { login: user } } = await apollo.apolloClient.mutate({
      mutation: LOGIN,

      variables: {
        model: {
          username,
          password
        }
      }
    });

    if (user) {
      Cookies.set('accessToken', user.token);
      Cookies.set('refreshToken', user.refreshToken);

      const userProfile = await getProfile();

      dispatch({
        type: 'LOGIN',
        payload: {
          user: userProfile,
        }
      });

      return user.token;
    }

    return Promise.reject(new Error('Account Not Found'));
  };

  const register = async (firstName, surname, email, username, phoneNumber, password) => {
    const { data: { add: user } } = await apollo.apolloClient.mutate({
      mutation: ADD,

      variables: {
        model: {
          firstName,
          surname,
          email,
          username,
          password,
          phoneNumber
        }
      }
    });

    if (user) {
      Cookies.set('accessToken', user.token);
      Cookies.set('refreshToken', user.refreshToken);

      const userProfile = await getProfile();

      return dispatch({
        type: 'REGISTER',
        payload: {
          user: userProfile
        }
      });
    }

    return Promise.reject(new Error('Account Already Exists'));
  };

  const openLoginDialog = () => {
    dispatch({
      type: 'OPEN_LOGIN_DIALOG',
    });
  };

  const closeLoginDialog = () => {
    dispatch({
      type: 'CLOSE_LOGIN_DIALOG',
    });
  };

  const openRegisterDialog = () => {
    dispatch({
      type: 'OPEN_REGISTER_DIALOG',
    });
  };

  const closeRegisterDialog = () => {
    dispatch({
      type: 'CLOSE_REGISTER_DIALOG',
    });
  };

  const openSuccessRegisterDialog = () => {
    dispatch({
      type: 'OPEN_SUCCESS_REGISTER_DIALOG',
    });
  };

  const closeSuccessRegisterDialog = () => {
    dispatch({
      type: 'CLOSE_SUCCESS_REGISTER_DIALOG',
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        openLoginDialog,
        closeLoginDialog,
        openRegisterDialog,
        closeRegisterDialog,
        openSuccessRegisterDialog,
        closeSuccessRegisterDialog
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
